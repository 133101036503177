"use client";

import React, { useRef, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import './other.css';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
}

const BarChartComponent: React.FC<BarChartComponentProps> = ({ groupData, selectedGroup, startDate, endDate }) => {
    const [chartData, setChartData] = useState<any>(null);
    const chartRef = useRef<any>(null);

    const baselines: { [key: string]: number } = {
        'Netflix': 1.5,
        'YouTube': 1.3,
        'Amazon': 1.2,
        'Viki': 1.3,
        'Default': 1.2,
    };

    useEffect(() => {
        if (groupData && selectedGroup && startDate && endDate) {
            const filteredData = groupData.filter((item: any) => {
                const itemDate = new Date(item.startTime);
                return itemDate >= startDate && itemDate <= endDate;
            });
            const processedData = processChartData(filteredData);
            setChartData(processedData);
        }
    }, [groupData, selectedGroup, startDate, endDate]);

    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.resize();
            }
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    const processChartData = (data: any) => {
        const keywordMapping: { [key: string]: string } = {
            // This is mapping the nameConf to the keyword to group the data
            'aiv-delivery.net': 'Amazon',
            'amazonvideo.com': 'Amazon',
            'Prime Video': 'Amazon',
            'amazon': 'Amazon',
            'nflxvideo.net': 'Netflix',
            'netflix.com': 'Netflix',
            'netflix': 'Netflix',
            'Netflix': 'Netflix',
            'logs.netflix.com': 'Netflix',
            'android.prod.ftl.netflix.com': 'Netflix',
            'android-appboot.netflix.com': 'Netflix',
            'tiktokv.us': 'Tiktok',
            'TikTok': 'TikTok',
            'viki.io': 'Viki',
            'googlevideo': 'YouTube',
            'youtube.com': 'YouTube',
            'youtube': 'YouTube',
            'Youtube': 'YouTube',
            'YouTube': 'YouTube',
        };

        const cssVars = getComputedStyle(document.documentElement);
        const colorMapping: { [key: string]: { background: string, border: string } } = {
            'YouTube': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Netflix': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Amazon': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Viki': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'TikTok': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
            'Baseline': {
                background: cssVars.getPropertyValue('--baseline-black'),
                border: cssVars.getPropertyValue('--baseline-border'),
            },
            'Other': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-boarder'),
            },
        };

        const aggregatedData: { [key: string]: { dataUsage: number, duration: number } } = {};
        const baselineData: { [key: string]: number } = {};

        data.forEach((item: any) => {
            const nameConf = item.nameConf;
            const dataUsage = item.dataUsage;
            const duration = item.duration;

            let domainGroup = 'Other';
            for (const keyword in keywordMapping) {
                if (nameConf.includes(keyword)) {
                    domainGroup = keywordMapping[keyword];
                    break;
                }
            }
            if (aggregatedData[domainGroup]) {
                aggregatedData[domainGroup].dataUsage += dataUsage;
                aggregatedData[domainGroup].duration += duration;
            } else {
                aggregatedData[domainGroup] = { dataUsage, duration };
            }

            if (baselineData[domainGroup]) {
                baselineData[domainGroup] += duration;
            } else {
                baselineData[domainGroup] = duration;
            }
        });

        const labels = Object.keys(aggregatedData);
        const dataUsage = Object.values(aggregatedData).map(item => item.dataUsage);
        const durations = Object.values(aggregatedData).map(item => item.duration);
        const backgroundColors = labels.map(label => colorMapping[label]?.background || 'rgba(0, 0, 0, 0.2)');
        const borderColors = labels.map(label => colorMapping[label]?.border || 'rgba(0, 0, 0, 1)');

        return {
            labels,
            datasets: [
                {
                    label: 'Data Usage',
                    data: dataUsage,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,
                    borderWidth: 1,
                    borderRadius: 10,
                },
                {
                    label: "Baseline",
                    data: Object.keys(baselineData).map(group => {
                        const baselineRate = baselines[group] || baselines['Default'];
                        return baselineRate * (baselineData[group] / 3600) * 1000 * 1000 * 1000;
                    }),
                    backgroundColor: colorMapping['Baseline'].background,
                    borderColor: colorMapping['Baseline'].border,
                    borderWidth: 1,
                    borderRadius: 10,
                }
            ],
            durations,
        };
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const formatDuration = (duration: number) => {
        const days = Math.floor(duration / (60 * 60 * 24));
        const hours = Math.floor(duration / (60 * 60)) % 24;
        const minutes = Math.floor(duration / 60) % 60;
        const seconds = Math.floor(duration) % 60;
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1000 * 1000 * 1000,
                    callback: function(value: any) {
                        return formatBytes(value);
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        const duration = chartData.durations[context.dataIndex];
                        return [
                            `${label}: ${formatBytes(value)}`,
                            `Duration: ${formatDuration(duration)}`,
                        ];
                    }
                }
            },
            datalabels: {
                display: false,
            },
        }
    };

    return (
        <div className='chart-container' style={{ width: '100%', height: '100%'}}>
            {chartData ? (
                <Bar ref={chartRef} data={chartData} options={options} />
            ) : (
                <div className='how-to'>Select a group and a date range to view your graphs.</div>
            )}
        </div>
    );
};

export default BarChartComponent;
